// Basic tabs BEM

.b-tabs {

	// Container for the actual tab buttons
	&__tabs {

	}

	// The tab button
	&__tab {


		&--active {

		}
	}

	// The containers for the contents in each tab
	&__panel {
		display: none;

		&--active {
			display: block;
		}
	}

}