/*
Additional BWI reset rules as we don't want to edit the third party _reset.scss
*/

* {
	box-sizing: border-box;
}

figure {
	margin: 0;
}

nav {
	ul {
		margin: 0;
		padding: 0;

		list-style-type: none;

		> li {
			list-style-type: none;
		}
	}

	a:link {
		text-decoration: none;
	}
}

iframe {
	vertical-align: top;
}

svg {
	height: auto;
	width: auto;
	max-height: 100%;
}

img {
	vertical-align: top;
}

button {
	border: none;
}

// remove default form styles
input, textarea, select {
	&:-moz-ui-invalid:not(output) {
		box-shadow: none;
	}
}