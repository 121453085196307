.l-span {
	&--narrow {
		max-width: 905px;
	}

	&--medium {
		max-width: 1200px;
		width: 100%;
	}

	&--white {
		overflow: auto; // stops child element margins expanding outside the background colour

		background-color: #fff;
	}

	&--light {
		overflow: auto; // stops child element margins expanding outside the background colour

		background-color: map_get($color-grey, 20);
	}

	&--dark {
		overflow: auto; // stops child element margins expanding outside the background colour

		background-color: map_get($color-green, 100);
	}

	&--green {
		overflow: hidden; // stops child element margins expanding outside the background colour

		background-color: map_get($color-green, 100);
		background: linear-gradient(to right, #41ac48 0%, #76c97c 100%);
		background-size: cover;
		background-position: center;

		color: #fff;
	}

	&--greentop {
		overflow: hidden; // stops child element margins expanding outside the background colour
		background-color: map_get($color-green, 100);
		background-size: cover;
		background-position: center;
		color: #fff;
		padding-top: 15px;
		@include breakpoint(tablet-up) {
			padding-top: 30px;
		}
	}

	&--grey {
		overflow: auto; // stops child element margins expanding outside the background colour

		background-color: map_get($color-grey, 50);
	}
}