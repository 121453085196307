// Basic grid BEM

$gutter: 42;

.b-columns {
	display: inline-block;

	@include breakpoint('tablet-up') {
		display: flex;
	}

	&__1of8 {
		width: 12.5%;
	}


	&__2of8 {
		width: 100%;
		@include breakpoint('tablet-up') {
			width: 35%;
		}
		@include breakpoint('desktop-up') {
			width: 25%;
		}
	}

	&__3of8 {
		width: 100%;
		@include breakpoint('tablet-up') {
			width: 37.5%;
		}
	}

	&__4of8 {
		width: 50%;
	}

	&__5of8 {
		width: 100%;
		@include breakpoint('tablet-up') {
			width: 65%;
		}
		@include breakpoint('desktop-up') {
			width: 62.5%;
		}
	}

	&__6of8 {
		width: 100%;
		@include breakpoint('tablet-up') {
			width: 75%;
		}
	}

	&__7of8 {
		width: 87.5%;
	}

// Donate page widths
	&__2of5 {
		width: 100%;
		@include breakpoint('tablet-up') {
			width: 40%;
		}
		@include breakpoint('desktop-up') {
			width: 30%;
		}
	}

	&__3of5 {
		width: 100%;
		@include breakpoint('tablet-up') {
			width: 60%;
		}
		@include breakpoint('desktop-up') {
			width: 70%;
		}
	}

	&__1of3 {
		width: 33.334%;
	}

	&__2of3 {
		width: 66.666%;
	}


	&--gutter {
		> *:not(:last-child) {
			margin-right: ($gutter / 2) + px;
			padding-right: ($gutter / 2) + px;
		}
	}

	&--gutter-wide {
		> *:not(:last-child) {
			margin-right: (150 / 2) + px;
			//padding-right: (50 / 2) + px;

			@include breakpoint('tablet-up') {
				margin-right: (100 / 2) + px;
			}
			@include breakpoint('desktop-up') {
				margin-right: (150 / 2) + px;
			}
		}
	}
}