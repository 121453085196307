textarea {
	height: 210px;

	resize: vertical;
}

// Basic button applies to forms and anchors
.button {
	display: inline-block;
	margin: 0;
	padding: .4em 1em;
	vertical-align: top;
	min-width: 137px;

	background-color: map_get($color-green, 100);

	color: #fff;
	font-family: Arial, sans-serif;
	text-align: center;
	text-decoration: none;

	transition: all .1s linear;

	&--wide {
		padding: .5em;
		width: 100%;
	}

	&:link, &:visited {
		color: #fff;
	}

	&:hover, &:active {
		background-color: map_get($color-green, 75);
	}

}



.label {
	display: block;
	margin-bottom: 20px;
	width: 90%;
	font-size: 19.2pt;
	font-weight: 100;
	background-color: #fff;

	cursor: pointer;

	&--textarea {
		align-items: baseline;
	}

	&__text {
		display: block;
		margin-bottom: 10px;
	}

	&__input {
		padding: 7px 10px;
		width: 100%;

		border: solid 1px map_get($color-grey, 40);

		font-family: Arial, sans-serif;

		&--error {
			margin-top: 5px;

			color: #c00;
			font-family: Arial, sans-serif;
			font-size: .8em;
		}
	}
}

.contact {
	&__response {
		background-color: map_get($color-green, 30);
	}
}

