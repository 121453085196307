html[data-useragent*='MSIE 10.0'] {


	.financial-reports {
		&__file, &__file:last-child {
			display: block;

			&::after {
				content: '';
			}
		}
	}

}