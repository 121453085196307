// Basic media BEM

.b-media {
	align-items: flex-start;
	display: flex;


	// The image
	&__item {
		margin-right: 20px;

		&--spaced {
			margin-right: 50px;
		}
	}

	// The text or description
	&__body {
		flex: 1 0 100px;
	}
}