// tables

.b-table {
	margin-bottom: .5em;
	max-width: 800px;

	&:not(:first-child) {
		margin-top: 2em;
	}

	th {
		padding: 10px 15px;

		background-color: map_get($color-green, 50);
		border-bottom: solid 5px #fff;

		color: #fff;
		text-align: left;
	}

	td {
		margin-top: 10px;
		padding: 10px 15px;

		background-color: map_get($color-grey, 20);
		border-bottom: solid 5px #fff;
	}
}