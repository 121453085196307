html {
	color: map_get($color-grey, 100);
	font-family: 'adelle-sans', Arial, sans-serif;
	font-size: 12.7pt;
	font-weight: 400;
	line-height: 1;
}

h1, h2, h3, h4, h5, h6, p, ul {
	margin: 0;

	color: inherit;
	font-size: inherit; // inherit so it gets the size from directly added classes or from wrappers
	font-weight: inherit;
	line-height: inherit;
}

p {
	max-width: 850px;
}

p:last-child {
	margin-bottom: 0;
}

li {
	-webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
	page-break-inside: avoid; /* Firefox */
	break-inside: avoid; /* IE 10+ */
}

hr {
	margin: 2em 0;

	border: none;
	border-bottom: solid 1px #ccc;
}


//  ###    ##    ###  ####         ###  ###   ####  ####   ###
//  #  #  #  #  #     #           #      #      #   #     #
//  ###   #  #   ##   ###          ##    #     #    ###    ##
//  #  #  ####     #  #              #   #    #     #        #
//  ###   #  #  ###   ####        ###   ###   ####  ####  ###
//

// Prefix type styles with .typo__
// No need to include typo as the base class as we assume the html settings and pointless adding typo to every tag
.t {

	// Hero text
	&__1 {
		font-family: 'adelle-sans', Arial, sans-serif;
		font-size: 46pt;
		font-weight: 300;
		letter-spacing: -.02em;
	}

	&__2 {
		font-family: 'adelle-sans', Arial, sans-serif;
		font-size: 20pt;
		font-weight: 100;
		line-height: 1.2;
		letter-spacing: -.01em;

		@include breakpoint(tablet-up) {
			font-size: 24pt;
		}
		@include breakpoint(desktop-up) {
			font-size: 28pt;
		}
	}

	&__3 {
		font-family: 'adelle-sans', Arial, sans-serif;
		font-size: 14pt;
		font-weight: 100;
		line-height: 1.3;
		letter-spacing: -.01em;
		margin-bottom: .5em;

		@include breakpoint(tablet-up) {
			font-size: 16pt;
		}
		@include breakpoint(desktop-up) {
			font-size: 19.2pt;
		}
	}

	&__4 {
		font-family: 'adelle-sans', Arial, sans-serif;
		font-size: 11pt;
		@include breakpoint(tablet-up) {
			font-size: 10pt;
		}
		@include breakpoint(desktop-up) {
			font-size: 12pt;
		}
	}

	&__5 {
		font-size: 10pt;
		@include breakpoint(tablet-up) {
			font-size: 10pt;
		}
		@include breakpoint(desktop-up) {
			font-size: 11.8pt;
		}
	}

	&__6 {
		font-family: 'adelle-sans', Arial, sans-serif;
		font-size: 16pt;
	}

	&--green {
		color: map_get($color-green, 100);
	}

	&--bold {
		font-weight: 700;
	}

	&--caps {
		text-transform: uppercase;
	}

	&--tight {
		line-height: 1.25;
	}

	&--header {
		margin-bottom: 1em;
	}

	&--narrow {
		max-width: 655px;
	}

	&--narrow-medium {
		max-width: 785px;
	}

	&--medium {
		max-width: 975px;
	}

}

// add a little more space above inpage headers, can't use :not(:first-child) as this breaks the homepage
p + .t__3,
ol + .t__3,
ul + .t__3 {
	margin-top: 1.2em;
}

a:link, a:visited {
	color: inherit;
	text-decoration: none;
}

a:hover {

}

// Styles you want to use in sectioning elements. Try not to put too much here, just stuff like link styles within body text
article, aside, figcaption, main, section {
	line-height: 1.25;

	a:link, a:visited {
		color: map_get($color-green, 100);
	}

	p {
		margin-bottom: .7em;

		line-height: 1.45;
	}

	// default list style
	ul {
		padding: 0;

		list-style-type: none;

		> li {
			display: flex;
			margin-bottom: .8em;
		}
	}

	ol {
		margin: 0;
		padding: 0;
	}
}


// More precise styles such as headers follow images, last paragraphs etc

blockquote {
	margin: 1.8em 0 1.8em 66px;

	> p {
	}
}

.column-header {
	margin-bottom: 1em;
}