html {
	background-color: #fff;
}

.header {
	height: 125px;
	//display: flex;
	color: #fff;
	position: fixed;
	background-color: #fff;
	z-index: 100;

	@include breakpoint(tablet-up) {
		height: 115px;
		display: flex;
		justify-content: space-between;
		position: relative;
	}

	&__logo {
		align-self: center;
		img {
			width: 75px;
			padding-top: 10px;
		}
		@include breakpoint(tablet-up) {
			img {
				position: absolute;
				top: 20px;
				left: 3%;
				width: 80px;
				padding-top: 0;
			}
		}
		@include breakpoint(desktop-up) {
			img {
				top: 16px;
				left: 0;
				width: 135px;
			}
		}
	}

	&__social {
		position: absolute;
		top: 0; right: 3%;
		z-index: 100;
		width: 100%;
		color: map-get($color-green, 100);
		text-transform: uppercase;
		font-size: 14pt;

		@include breakpoint(tablet-up) {
			position: absolute;
			top: 25px;
			width: auto;
			font-size: 16pt;
		}
		@include breakpoint(desktop-up) {
			right: 0;
		}

		img {
			margin-left: 15px;
		}

		&--tel {
			position: absolute;
			top: 45px; right: 20px;

			span {
				display: none;
			}

			@include breakpoint(tablet-up) {
				position: relative;
				top: 0; right: 0;
				span {
					display: inline-block;
				}
			}
		}
		&--facebook {
			position: absolute;
			top: 15px; right: 20px;
			@include breakpoint(tablet-up) {
				position: relative;
				top: 0; right: 0;
			}
		}
	}
}

.menu-toggle {
	height: 45px; width: 100%;
	//position: absolute; right: 0; top: 100px;
	z-index: 99;
	margin-top: 15px;

	background-color: map-get($color-green, 100);
	text-transform: uppercase;
	font-size: 15pt;

	&:hover, &:active {
		color: map-get($color-green, 100);
		background-color: map-get($color-grey, 50);
	}

	@include breakpoint(tablet-up) {
		display: none;
	}
}

.nav {
	font-size: 12pt;
	letter-spacing: .035em;
	color: map_get($color-grey, 50);
	width: 100%;
	z-index: 1;

	@include breakpoint(phablet-max) {
		height: 0;
		opacity: 0;
		overflow: hidden;
		padding-top: 0;

		transition: all .3s linear;

		&--open {
			height: 275px;
			opacity: 1;
		}
	}

	@include breakpoint(tablet-up) {
		position: absolute;
		bottom: 0;
		right: 3%;
		width: 95%;
		font-size: 10pt;
	}

	@include breakpoint(desktop-up) {
		font-size: 12pt;
		width: 80%;
		right: 0;
	}

	&__links {
		display: inline-block;
		height: 100%;
		background-color: #fff;
		opacity: .95;
		width: 100%;
		text-align: center;

		@include breakpoint(tablet-up) {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: transparent;
		}
	}

	&__link {
		display: block;
		text-transform: uppercase;
		font-weight: 700;
		padding: 8px 0;
		cursor: pointer;

		@include breakpoint(tablet-up) {
			line-height: 40px; // hack to make the text centred vertically
			border-top: 10px solid transparent;
			padding: 0;
		}
		@include breakpoint(desktop-up) {
			line-height: 65px;
		}

		&:link, &:visited {
			color: inherit;
		}

		&:hover, &:active, &--active {
			color: map-get($color-green, 100);
		}

	}

	&__active {
		color: map-get($color-green, 100);
	}
}

.nav-side {
	margin: 0;
	padding: 20px 0;
	//min-width: 310px;
	list-style-type: none;
	border-top: solid 1px map_get($color-grey, 40);
	border-bottom: solid 1px map_get($color-grey, 40);

	li {
		margin: 0;
	}

	&__link {
		display: block;
		margin-bottom: 1px;
		padding-top: 3px;

		&:link, &:visited {
			color: map_get($color-grey, 50);
			font-weight: 300;
		}

		&:hover, &:active, &--active {
			color: map_get($color-green, 100) !important;
			font-weight: 700 !important;
		}
	}
}

.maincontent {
	padding-top: 125px;
	@include breakpoint(tablet-up) {
		padding-top: 0;
	}
}

.hero {
	opacity: 0;
	position: relative;

	animation: fade-in .5s ease-out .6s;
	animation-fill-mode: forwards;

	&--1 {
		background-image: url(../img/hero-about-us.jpg);
		background-size: cover;
		height: 150px;

		@include breakpoint(tablet-up) {
			height: 275px;
		}
		@include breakpoint(desktop-up) {
			height: 345px;
		}
	}
}


.footer {
	padding-top: 30px;
	padding-bottom: 35px;
	line-height: 1.25;
	color: #fff;

	@include breakpoint(tablet-up) {
		align-items: flex-start;
		display: flex;
		justify-content: space-between;
		padding-top: 30px;
		padding-bottom: 25px;
	}

	@include breakpoint(desktop-up) {
		padding-top: 60px;
		padding-bottom: 65px;
	}

	&__logo {
		margin-top: 5px;
		margin-right: 7%;
	}

	&__links:nth-child(1) {
		padding-bottom: 8%;
		border-bottom: 1px solid #fff;
		margin-bottom: 8%;

		@include breakpoint(tablet-up) {
			border-bottom: none;
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}

	&__links:nth-child(2) {
		li:nth-child(1) {
			padding-bottom: 12px;
		}
		@include breakpoint(tablet-up) {
			border-left: 1px solid #fff;
			padding-left: 1.5%;
		}
		@include breakpoint(desktop-up) {
			padding-left: 3%;
		}
	}

	&__links:nth-child(3) {
		li:nth-child(1) {
			padding-bottom: 12px;
		}
		padding-top: 8%;
		padding-bottom: 8%;
		border-bottom: 1px solid #fff;
		margin-bottom: 8%;

		@include breakpoint(tablet-up) {
			padding-top: 0;
			border-bottom: none;
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}

	&__links {
		flex-grow: 0;
		margin-right: 8%;
		padding: 0;
		list-style-type: none;

		li {
			padding-bottom: 4px;
			margin-bottom: 0;
		}

		@include breakpoint(tablet-up) {
			margin-right: 2.5%;
		}
		@include breakpoint(desktop-up) {
			margin-right: 7%;
		}
	}

	&__legal {
		flex-grow: 1;
		list-style-type: none;

		@include breakpoint(tablet-up) {
			padding-left: 1.5%;
			border-left: 1px solid #fff;
		}
		@include breakpoint(desktop-up) {
			padding-left: 3%;
		}

		img {
			height: 30px;

			@include breakpoint(tablet-up) {
				height: 35px;
			}
			@include breakpoint(desktop-up) {
				height: 45px;
			}
		}
		p {
			margin-bottom: 0;
		}
		p:first-of-type {
			padding-bottom: 16px;
		}

		p:last-of-type {
			padding-top: 16px;
		}
	}

	a {
		&:link, &:visited {
			color: inherit;
		}

		&:hover, &:active {
			color: map-get($color-green, 100);
		}
	}
}

//************************** Page specific styles ****************************************
// home
.home__header {
	padding-top: 160px;
	@include breakpoint(tablet-up) {
		padding-top: 0;
	}
}

.home--centred {
	text-align: center;

	margin-top: 50px;
	@include breakpoint(tablet-up) {
		margin-top: 0;
	}

	img {
		margin: 10px 0;

		@include breakpoint(tablet-up) {
			margin: 12px 0;
		}
		@include breakpoint(desktop-up) {
			margin: 15px 0;
		}
	}
}

// Pair of images in the same physical space with an animation to show/hide one of the pair
.homegrid {
	display: flex;
	flex-wrap: wrap;

	&__item {
		flex: 1 0 25%;
		min-width: 25%;
		position: relative;

		@include breakpoint(tablet-up) {
			flex: 1 0 25%;
			min-width: 25%;
		}

		@include breakpoint(desktop-up) {
			flex: 1 0 16%;
			min-width: 16%;
		}

		> img {
			width: 100%;
		}
	}

	&__alt-img {
		position: absolute;
		top: 0; left: 0;
		animation: homegrid 10s ease-in-out 0s infinite alternate forwards;
		&--1 {
			animation-delay: -3s;
		}
		&--2 {
			animation-delay: -7s;
		}
		&--3 {
			animation-delay: -8s;
		}
		&--4 {
			animation-delay: -4.5s;
		}
	}
}


// about us
.about {
	align-items: flex-start;

	&--sidepanel {
		background-color: map_get($color-green, 100);
		background: linear-gradient(to right, #41ac48 0%, #76c97c 100%);
		color: #fff;
		padding: 28px 28px 35px 28px;

		margin-top: 30px;
		a {
			font-weight: 700;
			&:link, &:visited {
				color: inherit;
			}

			&:hover, &:active {
				color: map-get($color-grey, 100);
			}
		}

		ul {
			li {
				margin: 0;
			}
		}

		@include breakpoint(tablet-up) {
			margin-top: 0;
		}
	}
}
	// who we help
	.whowehelp--sidepanel {
		background-color: map_get($color-grey, 20);
		padding: 28px 28px 35px 28px;

		margin-top: 30px;

		@include breakpoint(tablet-up) {
			margin-top: 0;
			margin-bottom: 10px;
		}
	}

	// how we help
	.howwehelp {
		display: block;

		padding-bottom: 40px;
		border-bottom: solid 1px map_get($color-grey, 40);
		margin-bottom: 40px;

		@include breakpoint(desktop-up) {
			display: flex;
			align-items: flex-start;
		}

		&-left {
			width: 100%;
			margin-bottom: 25px;

			@include breakpoint(desktop-up) {
				width: 300px;
			}
		}

		&-right {
			width: 100%;

			@include breakpoint(desktop-up) {
				width: 600px;
				margin-left: 35px;
			}

			ul > li {
				display: block;
				line-height: 1.4;
			}
		}

		img {
			max-width: 225px;
			height: 172px;
			display: block;


			// normal images (not the svg icons in the same space)
			@include breakpoint(tablet-up) {
				max-width: 300px;
				height: 230px;
				margin: 0 auto;
			}

			// check to see if the image is an svg icon and scale accordingly
			&[src$=".svg"] {
				//border: solid 2px tomato;
				max-width: 150px;
				height: 115px;
				@include breakpoint(tablet-up) {
					max-width: 225px;
					height: 172px;
				}
				@include breakpoint(desktop-up) {
					max-width: 300px;
					height: 230px;
				}
			}
		}

		h3 {
			color: map_get($color-green, 100);
		}

		a {
			font-weight: 700;
		}

	}

	// Foodbank
	.shopping-list {
		background-image: url('../img/shopping-list-top.svg');
		background-position: top center;
		background-repeat: repeat-x;
		background-size: 20px 20px;

		padding : 40px 25px;

		p {
			line-height: 1.3;
		}

		.t__3 {
			line-height: 1;
		}
	}

	.foodbank-donate {
		margin-top: 10px;
		font-size: 14pt;
	}

	.foodbank-locations {
		border-top: solid 15px map_get($color-green, 100);
		margin-top: 30px;

		> div {
			padding: 30px;
		}

		@include breakpoint(tablet-up) {
			margin-top: 0;
		}
	}

	.foodbank-cell {
		margin-bottom: 30px;
		text-align: center;
		@include breakpoint(tablet-up) {
			margin-bottom: 15px;
		}
		@include breakpoint(desktop-up) {
			margin-bottom: 0;
		}

		img {
			height: 100%;
			width: 100%;
			mix-blend-mode: darken;
		}
	}

	// Volunteer
	.volunteer {
		section {
			ul {
				list-style-type: disc;
				padding-left: 1em;
				margin-left: 1em;

				li {
					display: list-item;
				}
			}
		}
	}

	// Donate
	.donate {

		&__row {
			height: 40px;
			margin-bottom: 20px;

			p {
				height: 40px;
				width: 70%;
				text-align: center;
				font-size: 20pt;

				@include breakpoint(tablet-up) {
					width: 60%;
				}
				@include breakpoint(desktop-up) {
					width: 70%;
				}
			}

			p, form {
				display: inline-block;
				vertical-align: top;
			}
		}

		&__button{
			height: 40px;
			display: inline-block;
			font-family: inherit;
			font-size: 14pt;
			color: #fff;
			text-align: center;
			margin: 0;
			background-color: map_get($color-green, 100);
			border: 0;
			cursor: pointer;
			outline: none;
		}
		&__button:hover, &__button:active {
			background-color: map_get($color-green, 75);
		}
	}

	// generic box
	.box {
		height: 100%;
		padding: 26px 27px;

		background-color: map_get($color-grey, 20);
		border-left: solid 10px map-get($color-green, 50);
	}

	// animate the home page grid
	@keyframes homegrid {
		0% {
			opacity: 0;
		}
		40% {
			opacity: 0;
		}
		60% {
			opacity: 1;
		}
		100% {
			opacity: 1;
		}
	}


