.l-container {
	margin: 0 auto;
	position: relative;
	padding-left: 3%;
	padding-right: 3%;

	&--large {
		margin-top: 40px;
		margin-bottom: 50px;

		@include breakpoint(tablet-up) {
			margin-top: 70px;
			margin-bottom: 80px;
		}
		@include breakpoint(desktop-up) {
			margin-top: 110px;
			margin-bottom: 130px;
		}
	}

	&--standard {
		margin-top: 25px;
		margin-bottom: 30px;
		@include breakpoint(tablet-up) {
			margin-top: 50px;
			margin-bottom: 60px;
		}
	}

	&--notop {
		margin-top: 0;
		margin-bottom: 60px;
	}

	&--small {
		margin-top: 16px;
		margin-bottom: 16px;
		@include breakpoint(tablet-up) {
			margin-top: 20px;
			margin-bottom: 20px;
		}
		@include breakpoint(desktop-up) {
			margin-top: 30px;
			margin-bottom: 30px;
		}
	}

	@include breakpoint('bigscreen-up') {
		padding-left: 0;
		padding-right: 0;
	}

}