
@mixin breakpoint($point) {

	// Device breakpoints


	@if $point == mobile-max {
		@media only screen and (max-device-width: 479px) {
			@content;
		}
	}
	@if $point == phablet-up {
		@media only screen and (min-device-width: 480px) {
			@content;
		}
	}
	@if $point == phablet-only {
		@media only screen and (min-device-width: 480px) and (max-width: 767px) {
			@content;
		}
	}
	@if $point == phablet-max {
		@media only screen and (max-width: 767px) {
			@content;
		}
	}
	@if $point == tablet-up {
		@media only screen and (min-width: 768px) {
			@content;
		}
	}
	@if $point == tablet-only {
		@media only screen and (min-width: 768px) and (max-width: 1024px) {
			@content;
		}
	}
	@if $point == tablet-max {
		@media only screen and (max-width: 1024px) {
			@content;
		}
	}
	@if $point == desktop-up {
		@media only screen and (min-width: 1025px) {
			@content;
		}
	}
	@if $point == bigscreen-up {
		@media only screen and (min-width: 1200px) {
			@content;
		}
	}

	// special cases

	// this is wide but short screens - often headers look too big on them

	// When the content breaks breakpoints


}

