// Basic grid BEM

$gutter-tight: 0;
$gutter: 20px;
$gutter-medium: 30px;
$gutter-spaced: 60px;

@include breakpoint('phablet-up') {

	.b-grid {
		//margin-bottom: -$gutter; // negative margin to remove the margin added by the cells

		&__row {
			display: flex;
			margin: (-$gutter) 0 $gutter (-$gutter); // negative margins to the top and left to counter-act the margins used for spacing cells, bottom adds space between rows
		}

		&__wrap {
			display: flex;
			flex-wrap: wrap;
			margin: (-$gutter) 0 $gutter (-$gutter); // negative margins to the top and left to counter-act the margins used for spacing cells, bottom adds space between rows
		}

		&__cell {
			flex: 0 0 0;
			padding: $gutter 0 0 $gutter; // spaces out cells, first items are absorbed by the negative item of the container
			position: relative;

			// these names specify the final desktop quantities
			&--1of2 {
				flex-basis: 50%;
				max-width: 50%;
			}

			&--1of3 {
				flex-basis: 100%;
				max-width: 100%;
				@include breakpoint(tablet-up) {
					flex-basis: 50%;
					max-width: 50%;
				}
				@include breakpoint(desktop-up) {
					flex-basis: 33.333%;
					max-width: 33.333%;
				}
			}

			&--2of3 {
				flex-basis: 66.666%;
				max-width: 66.666%;
			}

			&--1of4 {

				flex-basis: 25%;
				max-width: 25%;

				@include breakpoint('tablet-up') {
					flex-basis: 25%;
					max-width: 25%;
				}

				@include breakpoint('desktop-up') {
					flex-basis: 25%;
					max-width: 25%;
				}
			}

			&--1of5 {
				flex-basis: 100%;
				max-width: 100%;

				@include breakpoint('tablet-up') {
					flex-basis: 33.333%;
					max-width: 33.333%;
				}
				@include breakpoint('desktop-up') {
					flex-basis: 20%;
					max-width: 20%;
				}
			}

			&--1of6 {
				flex-basis: 16.666%;
				max-width: 16.666%;
			}
		}

		&__content {
			height: 100%;

			background-color: #fff;

			&--light {
				background-color: map_get($color-grey, 20);
			}
		}

		// less space between items
		&--tight {
			.b-grid__row {
				margin: (-$gutter-tight) 0 $gutter-tight (-$gutter-tight); // negative margins to the top and left to counter-act the margins used for spacing cells, bottom adds space between rows
			}

			.b-grid__wrap {

				margin: (-$gutter-tight) 0 $gutter-tight (-$gutter-tight); // negative margins to the top and left to counter-act the margins used for spacing cells, bottom adds space between rows
			}

			.b-grid__cell {
				padding: $gutter-tight 0 0 $gutter-tight; // spaces out cells, first items are absorbed by the negative item of the container
			}
		}


		// Medium space between items : use : b-grid--medium
		&--medium {
			.b-grid__row {
				margin: (-$gutter-medium) 0 $gutter-medium (-$gutter-medium); // negative margins to the top and left to counter-act the margins used for spacing cells, bottom adds space between rows
			}

			.b-grid__wrap {

				margin: (-$gutter-medium) 0 $gutter-medium (-$gutter-medium); // negative margins to the top and left to counter-act the margins used for spacing cells, bottom adds space between rows
			}

			.b-grid__cell {
				padding: $gutter-medium 0 0 $gutter-medium; // spaces out cells, first items are absorbed by the negative item of the container
			}
		}

		// more space between items
		&--spaced {
			.b-grid__row {
				margin: (-$gutter-spaced) 0 $gutter-spaced (-$gutter-spaced); // negative margins to the top and left to counter-act the margins used for spacing cells, bottom adds space between rows
			}

			.b-grid__wrap {

				margin: (-$gutter-spaced) 0 $gutter-spaced (-$gutter-spaced); // negative margins to the top and left to counter-act the margins used for spacing cells, bottom adds space between rows
			}

			.b-grid__cell {
				padding: $gutter-spaced 0 0 $gutter-spaced; // spaces out cells, first items are absorbed by the negative item of the container
			}
		}
	}


}